<template>
  <div class="header-page">
    <div class="container-fluid">
      <div class="header-box">
        <div class="header-box__mission">
          <router-link class="text-decoration-none" to="about"
            ><span>About Us</span></router-link
          >
        </div>
        <div class="header-box__smart">
          <router-link tag="span" :to="{ name: 'home' }">{{
            $t('header.brand')
          }}</router-link>
        </div>

        <!-- User auth -->
        <template v-if="me">
          <div
            class="header-box__signIn"
            @click="$router.push({ name: 'account' })"
          >
            <button class="sign_in">{{ $t('header.profile') }}</button>
          </div>
        </template>

        <!-- User non auth -->
        <template v-else>
          <div
            class="header-box__signIn"
            @click="$router.push({ name: 'sign-in' })"
          >
            <button class="sign_in">{{ $t('header.sign_in') }}</button>
          </div>
        </template>

        <div
          v-if="showMobileMenu"
          class="header-box__hamburger"
          @click="toggle"
        >
          <!-- om nom -->
          <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>

          <div class="menu" :class="{ active: showAppMobileMenu }">
            <Menu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './Menu.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState('User', ['me']),
    ...mapState('App', ['showAppMobileMenu']),
    showMobileMenu() {
      return this.$route?.meta?.showMobileMenu || false
    },
  },

  components: {
    Menu,
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_USER_MOBILE_MENU']),

    toggle() {
      this.TOGGLE_USER_MOBILE_MENU(!this.showAppMobileMenu)
    },
  },
}
</script>
