<template>
  <div ref="navMenu" class="menu-layout container-fluid">
    <div class="container">
      <div class="menu-box">
        <div class="row">
          <div class="list-item">
            <div class="col-md-4 col-sm-4 col-item">
              <div class="item">
                <div class="low-title" @click="close">
                  <span>{{ $t('header.menu.about') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.account') }}</span>
                </div>
                <div class="title">
                  <span>{{ $t('header.menu.features') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.arbitrage') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.paper') }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-item">
              <div class="item">
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.pricing') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.affiliate') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.pro') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.suport') }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-item">
              <div class="item">
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.pricing') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.contact') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.contact') }}</span>
                </div>
                <div class="title" @click="close">
                  <span>{{ $t('header.menu.contact') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState('App', ['showAppMobileMenu']),
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_USER_MOBILE_MENU']),

    close() {
      this.TOGGLE_USER_MOBILE_MENU(this.showAppMobileMenu)
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      e.key === 'Escape' && this.TOGGLE_USER_MOBILE_MENU(false)
    })
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
