<template>
  <div class="global-layout app-layout">
    <notifications />

    <div class="header">
      <Header />
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
    <div class="footer" v-if="!hideFooter">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
export default {
  components: {
    Header,
    Footer,
  },

  computed: {
    hideFooter() {
      const metaParam = this.$route?.meta?.footer
      return metaParam === undefined ? false : !metaParam
    },
  },
  mounted() {
    console.log(this.$t('about_page.description_magic'))
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
